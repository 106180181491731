exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-director-detail-js": () => import("./../../../src/templates/directorDetail.js" /* webpackChunkName: "component---src-templates-director-detail-js" */),
  "component---src-templates-director-list-js": () => import("./../../../src/templates/directorList.js" /* webpackChunkName: "component---src-templates-director-list-js" */),
  "component---src-templates-film-detail-js": () => import("./../../../src/templates/filmDetail.js" /* webpackChunkName: "component---src-templates-film-detail-js" */),
  "component---src-templates-slideshow-js": () => import("./../../../src/templates/slideshow.js" /* webpackChunkName: "component---src-templates-slideshow-js" */)
}

